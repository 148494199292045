<template>
  <Card
    title-class="capitalize"
    :description="
      $t('popups.orderCloseConfirm.titleCloseDescription', {
        market: marketTicker(currentPosition.market),
        side: currentPosition.side.toLowerCase(),
      })
    "
    :title="$t('popups.orderCloseConfirm.titleClosePosition')"
  >
    <div class="form-position-confirm">
      <!-- <div class="form-position-confirm__description">
        {{
          $t('popups.orderCloseConfirm.q1', {
            type:
              currentPosition.state === 'proposed' ? 'cancelling' : 'closing',
          })
        }}
      </div> -->
      <ItemLabelValue
        :label="$t('order.card.titles.amount')"
        :value="`${toCurrencyFormat(currentPosition.amount)} ${
          currentPosition.base_currency
        }`"
      />
      <ItemLabelValue
        :label="$t('order.card.titles.entryPrice')"
        :value="`${
          currentPosition.side === PositionSides.BUY
            ? PositionSideTypes.ask
            : PositionSideTypes.bid
        }  ${calcEntryPrice(currentPosition)}`"
      />
      <ItemLabelValue
        v-if="currentPosition.state !== 'proposed'"
        :label="$t('order.card.titles.closePrice')"
        :value="
          $t('popups.orderCloseConfirm.marketPrice', {
            price: `${
              currentPosition.side === PositionSides.BUY
                ? PositionSideTypes.bid
                : PositionSideTypes.ask
            } ${calcCurrentPrice(currentPosition, markets)}`,
          })
        "
      />
      <ItemLabelValue
        v-if="!isPending && +loyaltyFeeRate"
        :label="$t('blocks.titles.loyaltyFee')"
        :hint-options="{ top: '-55px', left: '100px' }"
        :hint-title="$t('blocks.titles.loyaltyFeeHint')"
        :value="`${loyaltyFeeRate.toFixed(2)}%`"
      />
      <ItemLabelValue
        v-if="!isPending"
        :label="$t('order.card.titles.estNetProfitLossBsv')"
        :is-red="
          isScalper
            ? false
            : currentPosition.net_pl - positionLoyaltyFeeValue < 0
        "
        :is-green="
          isScalper
            ? false
            : currentPosition.net_pl - positionLoyaltyFeeValue > 0
        "
        :value="
          isScalper
            ? 0
            : formatNetProfitLoss(
                currentPosition.net_pl - positionLoyaltyFeeValue,
                currentPosition.base_currency
              )
        "
      />
      <div
        v-if="
          !isPending &&
          +loyaltyFeeRate &&
          !settings.doNotShowLiquidityFeeWarning
        "
        v-html="$t('order.card.titles.liqFeeHint')"
        class="loyalty-hint"
      />
      <div
        v-if="isScalper"
        v-html="$t('order.card.titles.liqFeeScalping')"
        class="loyalty-hint"
      ></div>
      <div class="separator" />
      <div class="buttons">
        <Button
          :text="$t('popups.buttons.close100')"
          size="medium"
          type="secondary"
          class="confirm-btn"
          is-html
          @click="onConfirm"
        />
        <Button
          v-if="!closeStopButton.hidden"
          :text="
            currentPosition.stop_loss === closeStopButton.stopValue
              ? $t('popups.orderCloseConfirm.cancelStop')
              : $t('popups.orderCloseConfirm.stopLoss', {
                  percent: closeStopButton.stopValue,
                })
          "
          size="medium"
          type="secondary"
          is-html
          class="confirm-btn"
          @click="setStopLoss(closeStopButton.stopValue)"
        />
        <Button
          :text="$t('popups.buttons.close50')"
          size="medium"
          type="secondary"
          is-html
          class="confirm-btn"
          @click="onHalfClose"
        />
      </div>
    </div>
  </Card>
</template>
<script>
import ItemLabelValue from '@/components/ui/ItemLabelValue.vue';
import Button from '@/components/ui/Button.vue';
import Card from '@/components/ui/Card.vue';
// import Modal from '@/modules/modals/Modal.vue';
import { toCurrencyFormat } from '@/helpers/utils';
import { formatNetProfitLoss } from '../formaters';
import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useModals } from '@/modules/modals/api';
import { PositionTypes } from '@/modules/positions/helpers';
import { marketTicker } from '@/config/wrapMarkets';
import useClosePosition from '@/compositions/useClosePosition';
import { connApp } from '@/helpers/signalR';
import notify from '@/plugins/notify';
import usePositionCalcs from '@/compositions/usePositionCalcs';
import { PositionSideTypes } from '@/modules/positions/helpers';
import { PositionSides } from '@/config/constants';

export default {
  name: 'PositionsCloseConfirm',
  components: {
    ItemLabelValue,
    Button,
    Card,
  },
  setup() {
    const store = useStore();
    const { hideModal, modalsByName, modalStatus } = useModals();
    const { closePositionJustNow } = useClosePosition();
    const positionStatus = ref({});
    const isScalper = ref(false);

    const { calcClosePrice, calcEntryPrice, calcCurrentPrice } =
      usePositionCalcs();

    const currentPosition = computed(() => {
      return store.getters['positions/getCurrentPosition'];
    });

    const settings = computed(() => {
      return store.getters['settings/settings'];
    });

    const isPending = computed(() =>
      [PositionTypes.pending, PositionTypes.proposed].includes(
        currentPosition.value.type
      )
    );

    const closeStopButton = computed(() => {
      if (isPending.value || currentPosition.value.pl_percent < -35) {
        return {
          hidden: true,
        };
      }
      return calcStopValue(currentPosition.value.pl_percent);
    });

    const plToStop = {
      0: 0,
      '-15': 20,
      '-35': 40,
    };

    const closeModal = () => {
      hideModal(modalsByName.positionClose);
    };

    const calcStopValue = (pl) => {
      for (const key in plToStop) {
        if (pl > key) {
          return {
            stopValue: plToStop[key],
          };
        }
      }

      return {
        hidden: true,
      };
    };

    const setStopLoss = async (value) => {
      await store.dispatch('positions/changeUserPosition', {
        position_id: currentPosition.value.id,
        take_profit: currentPosition.value.take_profit || null,
        stop_loss: currentPosition.value.stop_loss === value ? null : value,
        update: true,
      });

      closeModal();
    };

    const isScalperPosition = (activeMarket, positionStatus) => {
      const currentPrice =
        currentPosition.value.side === 'sell'
          ? activeMarket.buy
          : activeMarket.sell;
      const priceMovementRate =
        (Math.abs(currentPosition.value.entry_price - currentPrice) /
          currentPosition.value.entry_price) *
        100;
      const netPl =
        currentPosition.value.net_pl - positionLoyaltyFeeValue.value;
      const firstCheckScalper =
        priceMovementRate <= positionStatus.maxScalpingPriceMovement &&
        netPl > positionStatus.scalpingStatus.minScalpingProfit;

      console.log('LOG: positionStatus', positionStatus);
      console.log('LOG: activeMarket', activeMarket);
      console.log('LOG: currentPosition', currentPosition.value);

      console.log('LOG: priceMovementRate', priceMovementRate);
      console.log('LOG: net pl', netPl);
      console.log(
        'LOG: priceMovementRate <= positionStatus.maxScalpingPriceMovement && netPl > positionStatus.scalpingStatus.minScalpingProfit',
        firstCheckScalper
      );

      if (!firstCheckScalper) {
        isScalper.value = false;
        console.log('LOG: NO SCALPER');
        return;
      }

      console.log(
        'LOG: isExceededScalpingLimit',
        positionStatus.scalpingStatus.isExceededScalpingLimit
      );

      if (firstCheckScalper) {
        if (positionStatus.scalpingStatus.isExceededScalpingLimit) {
          isScalper.value = true;
          return;
        } else {
          const scalpingPositionsCount =
            positionStatus.scalpingStatus.scalpingPositionsCount;
          const allowedScalpingPositionsCount =
            positionStatus.scalpingStatus.allowedScalpingPositionsCount;
          const scalpingNetBalance =
            positionStatus.scalpingStatus.scalpingNetBalance;

          console.log('LOG: scalpingPositionsCount', scalpingPositionsCount);
          console.log(
            'LOG: allowedScalpingPositionsCount',
            allowedScalpingPositionsCount
          );
          console.log('LOG: scalpingNetBalance', scalpingNetBalance);
          console.log(
            'LOG: scalpingPositionsCount + 1 > allowedScalpingPositionsCount && scalpingNetBalance + netPl > positionStatus.scalpingStatus.minScalpingProfit',
            scalpingPositionsCount + 1 > allowedScalpingPositionsCount &&
              scalpingNetBalance + netPl >
                positionStatus.scalpingStatus.minScalpingProfit
          );

          if (
            scalpingPositionsCount + 1 > allowedScalpingPositionsCount &&
            scalpingNetBalance + netPl >
              positionStatus.scalpingStatus.minScalpingProfit
          ) {
            isScalper.value = true;
            return;
          } else {
            isScalper.value = false;
            return;
          }
        }
      }
    };

    onMounted(async () => {
      const activeMarket = marketsById.value[currentPosition.value.market_id];

      try {
        positionStatus.value = await connApp.invoke('GetPositionFeeStatus', {
          positionId: currentPosition.value.id,
        });
        isScalperPosition(activeMarket, positionStatus.value);
      } catch (_) {
        notify({
          text: 'Server Error',
          type: 'error',
        });
      }

      if (activePosition.value) {
        store.dispatch(
          'volumeLimits/fetchPositionLoyaltyFeeValue',
          activePosition.value.id
        );
      }

      await store.dispatch('volumeLimits/fetchLoyaltyFeeRate', activeMarket.id);
      await store.dispatch('volumeLimits/fetchLoyaltyFeeInfo', activeMarket.id);
    });

    const loyaltyFeeRate = computed(
      () => store.getters['volumeLimits/loyaltyFeeRate']
    );

    const marketsById = computed(() => store.getters['markets/marketsById']);
    const markets = computed(() => store.getters['markets/markets']);

    const activeConnect = computed(
      () => store.getters['connectors/activeConnect']
    );

    const onConfirm = async () => {
      if (!activeConnect.value || !activeConnect.value.provider) {
        await store.dispatch('burger/login');
        return;
      }
      await closePositionJustNow();
      closeModal();
    };

    const activeMarket = computed(
      () => marketsById.value[currentPosition.value.market_id]
    );

    watch(
      () => activeMarket.value.usdPrice,
      (newV) => {
        if (newV) {
          isScalperPosition(activeMarket.value, positionStatus.value);
        }
      }
    );

    const onHalfClose = async () => {
      if (!activeConnect.value || !activeConnect.value.provider) {
        await store.dispatch('burger/login');
        return;
      }

      await store.dispatch('positions/changeUserPosition', {
        position_id: currentPosition.value.id,
        amount: currentPosition.value.amount / 2,
      });

      const activeMarket = marketsById.value[currentPosition.value.market_id];
      await store.dispatch(
        'volumeLimits/fetchGroupActiveAmount',
        activeMarket.id
      );

      store.dispatch('positions/toggleFlagHalfClose', true);

      onConfirm();
    };

    const profitLoss = computed(() => {
      return `${toCurrencyFormat(currentPosition.value.pl)}/${toCurrencyFormat(
        currentPosition.value.pl_percent
      )}%`;
    });

    const activePosition = computed(
      () => store.getters['positions/getCurrentPosition']
    );

    const positionLoyaltyFeeValue = computed(
      () => store.getters['volumeLimits/positionLoyaltyFeeValue']
    );

    return {
      settings,
      positionStatus,
      currentPosition,
      closeStopButton,
      formatNetProfitLoss,
      toCurrencyFormat,
      modalStatus,
      isPending,
      modalsByName,
      onConfirm,
      onHalfClose,
      setStopLoss,
      closeModal,
      profitLoss,
      positionLoyaltyFeeValue,
      loyaltyFeeRate,
      marketTicker,
      isScalper,
      PositionSideTypes,
      PositionSides,
      markets,
      calcClosePrice,
      calcEntryPrice,
      calcCurrentPrice,
    };
  },
};
</script>
<style lang="scss" scoped>
.form-position-confirm {
  display: flex;
  flex-direction: column;
  min-height: 190px;

  .loyalty-hint {
    margin: 7px 0;
    font-family: 'Gotham_Pro_Regular';
  }

  &__description {
    margin: 15px 0;
    font-family: Gotham_Pro_Regular;
    font-size: 15px;

    &.less-then {
      margin-top: -5px;
      margin-bottom: 20px;
    }
  }

  .separator {
    margin-top: 45px;
  }

  .buttons {
    position: absolute;
    display: flex;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    .confirm-btn {
      margin: 0 1px;

      &:first-child {
        margin: 0;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
